.bigToggleContainer {
  width: 300px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.flexRow {
  column-gap: 16px;
  display: flex;
}

.sections {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 24px;
}