.body {
  text-align: center;
}

.errorBoundary {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  margin-top: 36px;
  margin-bottom: 12px;
  text-align: center;
}
