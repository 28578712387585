:root {
  --color-backgroundOverlay-lightMode: rgba(0, 0, 0, 0.3);
  --color-cardBackground-lightMode: #fff;
  --color-dimmer-lightMode: rgba(0, 0, 0, 0.36);
  --color-floatingElement-lightMode: #fff;
  --color-ghost-lightMode: #c6c6c6;
  --color-menuPopup-lightMode: #fff;
  --color-primary-lightMode: #201f2a;
  --color-red-lightMode: #fd4646;
  --color-redHover-lightMode: #dd1111;
  --color-secondary-lightMode: #9796a5;
  --color-shader-lightMode: #f5f4fe;
  --color-skeletonBase-lightMode: #ebebeb;
  --color-skeletonHighlight-lightMode: #f5f5f5;
  --color-tertiary-lightMode: #dedde5;
  --color-websiteBackground-lightMode: #fff;
  --color-white-lightMode: #fff;
}
