.body {
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 24px;
  padding-top: 28px;
}

.separator {
  border-bottom: 1px solid var(--color-tertiary);
  width: 100%;
}

.titleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  text-align: center;
}
