.active {
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.inactive {
  background: transparent;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}