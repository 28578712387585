.ldsDualRing {
  display: block;
  margin: auto;
  width: 64px;
  height: 64px;
}

.ldsDualRing:after {
  box-sizing: border-box;
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  border-style: solid;
  border-width: 4px;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Colors */
.primary:after {
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
}

.secondary:after {
  border-color: var(--color-secondary) transparent var(--color-secondary) transparent;
}

.white:after {
  border-color: var(--color-white) transparent var(--color-white) transparent;
}